import { EditType } from "src/data/editField/editFieldTypes"
import { AutomatedTestsNode } from "src/data/homeProfileSettings/logic/sectionFireCO/fireCONodes"
import { ISectionProfile } from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import { langKeys } from "src/i18n/langKeys"

export const SECTION_FIRE_CO_KEY = "section-fire-co"

export const SectionFireCO: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) => t(langKeys.fire_and_co_alarm_title),
  key: SECTION_FIRE_CO_KEY,
  description: "",
  contents: [AutomatedTestsNode],
} as const
