import styled from "styled-components"

import { useQueryClient } from "@tanstack/react-query"

import { SettingsView } from "src/components/Settings/SettingsView"
import { NoiseAlertTab } from "src/components/SettingsProfiles/NoiseAlertTab"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { profilesCache } from "src/data/homeProfiles/queries/homeProfilesCache"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { SectionFireCO } from "src/data/homeProfileSettings/logic/sectionFireCO/sectionFireCO"
import { SectionSecurity } from "src/data/homeProfileSettings/logic/sectionSecurity/sectionSecurity"
import { IOrganization } from "src/data/organizations/types/organizationTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function MonitoringProfileSettings({
  profileResponse,
  organizationResponse,
}: {
  profileResponse: IProfileResponse
  organizationResponse: IOrganization
}) {
  const cache = useQueryClient()

  function clearProfileCache() {
    cache.invalidateQueries(profilesCache.item(profileResponse.id))
  }

  const { t, langKeys } = useTranslate()

  const hasUsedEureka = useFeatureAvailability({ feature: "eureka" })

  const tabs: ITab[] = [
    {
      id: "noise",
      label: t(langKeys.settings_noise_alerts_title),
      view: (
        <NoiseAlertTab
          profileResponse={profileResponse}
          organizationResponse={organizationResponse}
        />
      ),
    },
    {
      id: "security",
      label: t(langKeys.home_security_alarm_title),
      view: (
        <SectionContainer>
          <SettingsView
            title={t(langKeys.home_security_alarm_title)}
            subtitle={SectionSecurity.description}
            content={SectionSecurity.contents}
            currentSettings={[
              { profile: profileResponse, org: organizationResponse },
            ]}
            viewConfig={{ onSubmitSuccess: clearProfileCache }}
          />
        </SectionContainer>
      ),
    },
    {
      id: "smoke",
      label: t(langKeys.fire_and_co_alarm_title),
      view: (
        <SectionContainer>
          <SettingsView
            title={t(langKeys.fire_and_co_alarm_title)}
            subtitle={SectionFireCO.description}
            content={SectionFireCO.contents}
            currentSettings={[
              { profile: profileResponse, org: organizationResponse },
            ]}
            viewConfig={{ onSubmitSuccess: clearProfileCache }}
          />
        </SectionContainer>
      ),
      hidden: !hasUsedEureka.available,
    },
  ].filter((it) => !it.hidden)

  return (
    <Container>
      <Tabs labels={tabs}>
        {tabs.map((tab) => (
          <TabPanel key={tab.label}>{tab.view}</TabPanel>
        ))}
      </Tabs>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  gap: ${spacing.XL};
`

const SectionContainer = styled.div`
  min-width: 500px;
  overflow: auto;
`
