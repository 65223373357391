import { EditType, IStatic } from "src/data/editField/editFieldTypes"
import { langKeys } from "src/i18n/langKeys"

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export const StaticOnMixin: IStatic<any> = {
  type: EditType.STATIC,
  id: "mixin",
  title: "<MIXIN>",
  info: "",
  defaultValue: langKeys.on,
  payload: () => undefined,
  storedValue: (): string => langKeys.on,
  endpointUrl: () => "",
  inlineEdit: true,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export const NotifyTeamMembers: IStatic<any> = {
  ...StaticOnMixin,
  type: EditType.STATIC,
  id: "notify-team-members",
  title: langKeys.profiles_notify_team,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
export const NotifyResponders: IStatic<any> = {
  ...StaticOnMixin,
  type: EditType.STATIC,
  id: "notify-team-responders",
  title: langKeys.profiles_notify_responders,
}
