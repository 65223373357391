import { SettingToggle } from "src/components/Settings/Setting/SettingToggle"
import { usePatchFireCOAutomatedTests } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { IProfile } from "src/data/homeProfiles/types/homeProfilesTypes"
import { useTranslate } from "src/i18n/useTranslate"

export function ToggleFireCOAutomatedTests({ profile }: { profile: IProfile }) {
  const { t, langKeys } = useTranslate()
  const patchFireCOAutomatedTests = usePatchFireCOAutomatedTests()
  const autoTestEnabled = !!profile.fire_and_co_alarm?.automated_testing

  const title = t(langKeys.fire_and_co_alarm_automated_tests_title)
  const info = t(langKeys.fire_and_co_alarm_automated_tests_footer)

  const getValue = () => {
    if (patchFireCOAutomatedTests.isLoading) {
      const optimisticState = !!patchFireCOAutomatedTests.variables?.enabled
      return optimisticState
    }
    return !!autoTestEnabled
  }
  const value = getValue()

  return (
    <SettingToggle
      title={title}
      description={info}
      value={value}
      onSave={async (v) => {
        try {
          await patchFireCOAutomatedTests.mutateAsync({
            profileId: profile.id,
            enabled: !!v,
          })
          return { isSuccess: true }
        } catch (error) {
          return { isSuccess: false }
        }
      }}
    ></SettingToggle>
  )
}
