import { EditType, IStatic } from "src/data/editField/editFieldTypes"
import { profileEndpointUrl } from "src/data/homeProfileSettings/logic/shared/endpoints"
import {
  NotifyResponders,
  NotifyTeamMembers,
  StaticOnMixin,
} from "src/data/homeProfileSettings/logic/shared/genericFields"
import {
  ISectionProfile,
  ISliderProfile,
  IToggleProfile,
} from "src/data/homeProfileSettings/types/homeProfilesSettingTypes"
import { langKeys } from "src/i18n/langKeys"

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
const Motion: IStatic<any> = {
  ...StaticOnMixin,
  title: langKeys.motion,
  id: "motion",
}

const GracePeriod: ISliderProfile = {
  id: "grace-period",
  title: langKeys.security_alarm_grace_period_title,
  defaultValue: 45,
  info: "",
  max: 60,
  min: 0,
  unit: "s",
  warning: "",
  payload: (value: number) => {
    return { security_alarm: { grace_period: Number(value) } }
  },
  storedValue: (settings) =>
    settings.profile.security_alarm?.grace_period ?? 45,
  endpointUrl: (s) => profileEndpointUrl(s.profile),
  type: EditType.SLIDER,
}

const TriggersOn: ISectionProfile = {
  type: EditType.SECTION,
  key: "triggers-on",
  title: (t) => t(langKeys.security_alarm_timeline_trigger_header),
  description: (t) => t(langKeys.security_alarm_timeline_trigger_footer),
  contents: [
    //
    Motion,
    GracePeriod,
  ],
}

const FirstResponseSection: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) => t(langKeys.security_alarm_timeline_first_warning_header),
  key: "first-response",
  contents: [NotifyTeamMembers],
}

const SecondResponseSection: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) => t(langKeys.security_alarm_timeline_second_warning_header),
  description: (t) => t(langKeys.security_alarm_timeline_second_warning_footer),
  key: "second-response",
  contents: [NotifyResponders],
}

const InstantAlarm: IToggleProfile = {
  type: EditType.TOGGLE,
  title: langKeys.instantly_turn_on_alarm_title,
  info: langKeys.instantly_turn_on_alarm_footer,
  id: "instant-alarm",
  trueVal: true,
  falseVal: false,
  defaultValue: false,
  payload: (enabled: boolean) => {
    return {
      security_alarm: {
        instantly_turn_on: enabled,
      },
    }
  },
  storedValue: (s): boolean => !!s.profile.security_alarm?.instantly_turn_on,
  endpointUrl: (s) => profileEndpointUrl(s.profile),
  inlineEdit: true,
}

const AdvancedSection: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) => t(langKeys.device_settings_advanced_settings_title),
  key: "advanced-section",
  contents: [InstantAlarm],
}

export const SectionSecurity: ISectionProfile = {
  type: EditType.SECTION,
  title: (t) => t(langKeys.home_security_alarm_title),
  key: "section-security",
  description: "",
  contents: [
    TriggersOn,
    FirstResponseSection,
    SecondResponseSection,
    AdvancedSection,
  ],
}
